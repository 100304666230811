var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: "PERMISSION_FORM_" + _vm.id },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      staticStyle: { "margin-right": "auto" },
                      attrs: { size: "sm", variant: "secondary" },
                      on: { click: _vm.saveAccessPolicy },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("button.save_access_policy")) + " "
                      ),
                    ]
                  ),
                  _vm.canEdit()
                    ? _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.ok },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              on: { dismissed: _vm.dismissAlert },
              model: {
                value: _vm.showError,
                callback: function ($$v) {
                  _vm.showError = $$v
                },
                expression: "showError",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "b-alert",
            {
              attrs: { variant: "warning", dismissible: "" },
              on: { dismissed: _vm.dismissWarning },
              model: {
                value: _vm.showWarning,
                callback: function ($$v) {
                  _vm.showWarning = $$v
                },
                expression: "showWarning",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.warnMsg) + " "),
            ],
            1
          ),
          _c(
            "b-alert",
            {
              attrs: { variant: "success", dismissible: "" },
              on: { dismissed: _vm.dismissSuccess },
              model: {
                value: _vm.showSuccess,
                callback: function ($$v) {
                  _vm.showSuccess = $$v
                },
                expression: "showSuccess",
              },
            },
            [
              _c("font-awesome-icon", { attrs: { icon: ["far", "check"] } }),
              _vm._v("  " + _vm._s(_vm.successMsg) + " "),
            ],
            1
          ),
          _c("PermissionList", {
            attrs: {
              permissionIds: _vm.permissionIds,
              isOwnUser: _vm.isOwnUser,
              permissionProperties: _vm.permissionProperties,
              title: "Customize Permission",
            },
            on: { selected: _vm.permissionSelected, alert: _vm.listWarning },
          }),
        ],
        1
      ),
      _vm.accessPolicyShow
        ? _c("AccessPolicyModal", {
            attrs: {
              id: _vm.accessPolicyId,
              permissions: _vm.permissions,
              show: _vm.accessPolicyShow,
              title: _vm.accessPolicyTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.accessPolicyShow = $event
              },
              success: _vm.accessPolicySuccess,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }