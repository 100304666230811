<template>
  <div :id="`PERMISSION_FORM_${id}`" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow"
      @hidden="$emit('update:show', false)"
    >
    
      <b-alert variant="danger" dismissible v-model="showError" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
      </b-alert>
      <b-alert variant="warning" dismissible v-model="showWarning" @dismissed="dismissWarning">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ warnMsg }}
      </b-alert>
      <b-alert variant="success" dismissible v-model="showSuccess" @dismissed="dismissSuccess">
        <font-awesome-icon :icon="['far', 'check']"/>&nbsp;&nbsp;{{ successMsg }}
      </b-alert>

      <PermissionList :permissionIds="permissionIds" :isOwnUser="isOwnUser" :permissionProperties="permissionProperties" @selected="permissionSelected" @alert="listWarning" title="Customize Permission"/>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="secondary" @click="saveAccessPolicy" style="margin-right: auto">
          {{ $t('button.save_access_policy') }}
        </b-button>
        <b-button v-if="canEdit()" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <AccessPolicyModal v-if="accessPolicyShow" :id="accessPolicyId" :permissions="permissions" :show.sync="accessPolicyShow" @success="accessPolicySuccess" :title="accessPolicyTitle"/>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { strRandom } from '@/helpers';
import AccessPolicyModal from '@/components/modal/AccessPolicyModal';

export default {
  name: 'PermissionModal',
  components: {
    PermissionList: () => import('@/components/Permission/PermissionList'),
    AccessPolicyModal
  },
  props: {
    show:      { type: Boolean, default: false },
    title:     { type: String,  default: null },
    selected:  { type: Array,   default: () => [] },
    permissionProperties:  { type: Array,   default: () => [] },
    isOwnUser: { type: Boolean, default: false }
  },
  data() {
    return {
      id: strRandom(5),
      permissionName: 'USER',
      alertMsg: null,
      warnMsg: null,
      successMsg: null,
      showWarning: false,
      showSuccess: false,
      state: {
        modalShow: false  
      },
      permissions:  [],
      accessPolicyId: null,
      accessPolicyShow: false
    }
  },
  created() {
  },
  beforeDestroy() {
  },
  watch: {
    show: function(newValue) {
      if(newValue != this.state.modalShow) {
        this.state.modalShow = newValue;
        this.alertMsg = null;
        this.warnMsg = null;
        this.showWarning = false;
        this.permissions.splice(0, this.permissions.length, ...(this.selected || []));
      }
    },
  },
  computed: {
    accessPolicyTitle() {
      return this.accessPolicyId && this.accessPolicyId.indexOf('ACCESS_POLICY_NEW') == -1? this.$t('access_policy.title_detail'): this.$t('access_policy.title_new');
    },
    labelTitle() {
      return this.title? this.title : this.$t('permission.title.permissions');
    },
    showError() {
      return this.alertMsg != null;
    },
    permissionIds() {
      return this.permissions? this.permissions.map(i => i.uuId):[];
    }
  },
  methods: {
    ok() {
      if(this.permissions.length < 1) {
        this.alertMsg = this.$t('permission.error.at_least_one_hint');
        this.scrollToTop();
      } else {
        this.$emit('success', cloneDeep(this.permissions));
        this.$emit('update:show', false);
      }
    },
    permissionSelected(selected) {
      this.permissions.splice(0, this.permissions.length, ...selected);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    dismissWarning() {
      this.warnMsg = null;
      this.showWarning = false;
    },
    dismissSuccess() {
      this.successMsg = null;
      this.showSuccess = false;
    },
    scrollToTop() {
      document.querySelector(`#PERMISSION_FORM_${this.id}`).scrollIntoView();
    },
    listWarning(message) {
      this.warnMsg = message;
      this.showWarning = this.warnMsg !== null;
    },
    saveAccessPolicy() {
      this.accessPolicyId = `ACCESS_POLICY_NEW_${strRandom(5)}`;
      this.accessPolicyShow = true;
    },
    accessPolicySuccess(payload) {
      this.successMsg = payload.msg;
      this.showSuccess = true;
    }
  }
}
</script>

